

import React, { useState } from 'react';
import { easeInOut, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaWhatsapp, FaLinkedin, FaEnvelope, FaMobile } from 'react-icons/fa';
function Services() {
  const [showContent, setShowContent] = useState(false); // State to manage visibility

  const containerVariants = {
    hidden: { 
      opacity: 0, 
      x: '100vw',
      transition: {
        staggerChildren: 0.5,
      } 
    },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: { 
        type: 'tween',
        staggerChildren: 0.4,
        when: "beforeChildren",
      }
    },
  };

  const homeDotPatter = {
    visible:{
      scale:[1,1.3],
      transition:{
        duration:15,
        repeat:Infinity,
        repeatType:"reverse",
        ease:easeInOut
      }
    },
    initial:{
      scale:1
    }
  };

  // Variants for text fade-in animation
  const textVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        duration: 1.5, // 1 second fade-in
      },
    },
  };
  return (
    <>
   <motion.div 
        className='container-fluid' 
        id="services"  
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        onAnimationComplete={() => setShowContent(true)} // Trigger visibility
      >
        <div className='container-fluid ph-0 mh-5' style={{ position: "inherit", zIndex: 1 }}>
          <div className='row d-flex align-items-center vh-100'>
            <div className='col-8 home-left pt-5 mt-5'>
              {showContent && ( // Conditionally animate text after containerVariants completes
                <>
                  <motion.h2 variants={textVariants} initial="hidden" animate="visible" >Chat Smarter, Not Harder With Buzzvortex Media</motion.h2>
                 
                  <motion.div variants={textVariants} initial="hidden" animate="visible">
                    <p className='text-white'>Buzzvortex Media is an influential and purposeful marketing cohesiveness company that is
all about changing marketing into the ultimate business growth driver. With a good grasp of
the market and a push for creativity, we make use of exact information and exclusive
creativity to accomplish good outcomes. We are a repute proofing ourselves and keeping
pace with digitization. Join us to experience the digital transformation thence the integration
of the digital world with the marketing of the business.</p>
                <h4 className='text-white fw-bold'>Mission</h4>
                <p className='text-white'>Our trajectory started with a plan to redefine marketing in corporate strategy since the
outset, we have been faithful to our mission of not only delivering but surpassing client
expectations by delivering exceptional outcomes. We are a group of experienced
professionals with the capacity to plan strategically and execute the campaign at every step
for our clients&#39; growth.</p>
<h4 className='text-white fw-bold'>Vision</h4>
<p className='text-white'>In Buzzvortex Media, we have a strong feeling to be a true differentiator. We are advocates
of synergy and are busily cooperating with our customers to navigate all the business
challenges that they encounter. Providing end-to-end marketing solutions to enhance brand
visibility and drive measurable business growth is what our company is about.</p>
                  </motion.div>
                </>
              )}
            </div>
            <div className='col-4 position-relative'>
              <ul className='list-unstyled social-sides'>
                <li><Link to="https://instagram.com"><motion.div whileHover={{ scale: 1.2 }} transition={{ type: 'spring', stiffness: 300 }}>
                <FaInstagram style={{ color: "#fff" }} size="2em" />
                </motion.div>
                </Link></li>
                <li><Link><motion.div whileHover={{ scale: 1.2 }} transition={{ type: 'spring', stiffness: 300 }}><FaFacebook style={{ color: "#fff" }} size="2em" /></motion.div></Link></li>
                <li><Link><motion.div whileHover={{ scale: 1.2 }} transition={{ type: 'spring', stiffness: 300 }}><FaWhatsapp style={{ color: "#fff" }} size="2em" /></motion.div></Link></li>
                <li><Link><motion.div whileHover={{ scale: 1.2 }} transition={{ type: 'spring', stiffness: 300 }}><FaLinkedin style={{ color: "#fff" }} size="2em" /></motion.div></Link></li>
              </ul>
              <motion.img 
                src={`${process.env.PUBLIC_URL}/patterDots.png`} 
                className='homeDotPatter' 
                variants={homeDotPatter} 
                initial="initial" 
                animate="visible" 
              />
            </div>
          </div>
         
        </div>
      </motion.div>
  </>
  )
}

export default Services