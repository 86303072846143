import React, { useState } from 'react';
import { easeInOut, motion } from 'framer-motion';
import AnimCursor from '../components/AnimCursor';

function Branding() {
    const [showContent, setShowContent] = useState(false); // State to manage visibility

  const containerVariants = {
    hidden: { 
      opacity: 0, 
      x: '100vw',
      transition: {
        staggerChildren: 0.5,
      } 
    },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: { 
        type: 'tween',
        staggerChildren: 0.4,
        when: "beforeChildren",
      }
    },
  };

  const homeDotPatter = {
    visible:{
      scale:[1,1.3],
      transition:{
        duration:15,
        repeat:Infinity,
        repeatType:"reverse",
        ease:easeInOut
      }
    },
    initial:{
      scale:1
    }
  };

  // Variants for text fade-in animation
  const textVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        duration: 1.5, // 1 second fade-in
      },
    },
  };
  return (
    <>
    <AnimCursor/>
    <motion.div 
         className='container-fluid' 
         id="branding"  
         variants={containerVariants}
         initial="hidden"
         animate="visible"
         exit="exit"
         onAnimationComplete={() => setShowContent(true)} // Trigger visibility
       >
         <div className='container-fluid ph-0 mh-5' style={{ position: "inherit", zIndex: 1 }}>
           <div className='row d-flex align-items-center vh-100'>
           {showContent && ( // Conditionally animate text after containerVariants completes
            <>
            <div className='row pt-5 mt-5 d-flex align-items-stretch'>
            <motion.h2 variants={textVariants} initial="hidden" animate="visible" className='mb-5 fs-1 text-purple'>Branding</motion.h2>
            
            
              
                   
                  
                   <motion.div className='col-md-4 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>Brand Strategy</h3>
                        <p>
                        Our plan encompasses what the brand stands for, its values, its
target audience, and how the brand differentiates itself from the competition. The
brand voice is consistently optimised in which a brand communicates with its
audience and reflects its personality and values.
                        </p>
                    </div>
                   </motion.div>

                   <motion.div className='col-md-4 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>Brand Values</h3>
                        <p>
                        We focus on communicating brand values design, color, and
communication of the product. To attract the consumer&#39;s notice by standing out on
the shelves or to inform the consumer about relevant details of the product.
                        </p>
                    </div>
                   </motion.div>

                   <motion.div className='col-md-4 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>Brand Identity</h3>
                        <p>
                        It is the aesthetic and emotional signature of a brand. All those elements that are
developed by a company to portray the right image in front of the consumer, are
termed as brand identity.
                        </p>
                        <p>Brand identity has the following key components:</p>
                        <ul className='d-flex justify-content-between p-0 list-unstyled'>
                            <li className='text-white'>Logo</li><li className='text-white'>Color Scheme</li><li className='text-white'>Typography</li><li className='text-white'>Packaging</li>
                        </ul>
                    </div>

                    
                   </motion.div>
                   
                
                   </div>
             </>
            )}
            
           </div>
          </div>
        
       </motion.div>
   </>
  )
}

export default Branding