import React from 'react';
import './App.css';
import About from './pages/About';

import Home from './pages/Home';
import { BrowserRouter as Router,Route, Routes, useLocation } from "react-router-dom";
import Services from './pages/Services';
import { AnimatePresence } from 'framer-motion';
import Contact from './pages/Contact';
import Header from './components/Header';
import DigitalMarketing from './pages/DigitalMarketing';
import Branding from './pages/Branding';
import TraditionalMarketingServices from './pages/TraditionalMarketingServices';
import OtherSpecializedServices from './pages/OtherSpecializedServices';
import ItServices from './pages/ItServices';
function App() {
 
  return (
   <>
  <AnimatePresence mode='wait'>
  <Router>
   <Header />
   <Routes>
     <Route exact path='/' element={<Home />} />
     <Route exact path='/about' element={<About />} />
     <Route exact path='/services' element={<Services />} />
     <Route exact path='/contact' element={<Contact />} />
     <Route exact path='/digital-marketing' element={<DigitalMarketing />} />
     <Route exact path='/branding' element={<Branding />} />
     <Route exact path='/traditional-marketing-services' element={<TraditionalMarketingServices/>} />
     <Route exact path='/other-specialized-services' element={<OtherSpecializedServices />} />
     <Route exact path='/it-services' element={<ItServices />} />
   </Routes>
  </Router>
  </AnimatePresence>
   
   </>
  );
}

export default App;
