import React, { useState } from 'react';
import { easeInOut, motion } from 'framer-motion';
import AnimCursor from '../components/AnimCursor';

function OtherSpecializedServices() {
    const [showContent, setShowContent] = useState(false); // State to manage visibility

  const containerVariants = {
    hidden: { 
      opacity: 0, 
      x: '100vw',
      transition: {
        staggerChildren: 0.5,
      } 
    },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: { 
        type: 'tween',
        staggerChildren: 0.4,
        when: "beforeChildren",
      }
    },
  };

  const homeDotPatter = {
    visible:{
      scale:[1,1.3],
      transition:{
        duration:15,
        repeat:Infinity,
        repeatType:"reverse",
        ease:easeInOut
      }
    },
    initial:{
      scale:1
    }
  };

  // Variants for text fade-in animation
  const textVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        duration: 1.5, // 1 second fade-in
      },
    },
  };
  return (
    <>
    <AnimCursor/>
    <motion.div 
         className='container-fluid' 
         id="about"  
         variants={containerVariants}
         initial="hidden"
         animate="visible"
         exit="exit"
         onAnimationComplete={() => setShowContent(true)} // Trigger visibility
       >
         <div className='container-fluid ph-0 mh-5' style={{ position: "inherit", zIndex: 1 }}>
           <div className='row d-flex align-items-center vh-100'>
           {showContent && ( // Conditionally animate text after containerVariants completes
            <>
            <div className='row pt-5 mt-5 d-flex align-items-stretch'>
            <motion.h2 variants={textVariants} initial="hidden" animate="visible" className='mb-5 fs-1 text-purple'>Other Specialized Services</motion.h2>
            
            
              
                   
                  
                   <motion.div className='col-md-4 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>Marketing Research</h3>
                        <p>
                        Understood through studies, it is the research of market dynamics,
consumer behaviour, and trends in the industry. We need that to make valuable business
decisions, design effective marketing strategies, and find new opportunities.
                        </p>
                    </div>
                   </motion.div>

                   <motion.div className='col-md-4 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>Video Production</h3>
                        <p>
                        We learn in the production of videos across a spectrum of marketing
purposes; whether it is a social media one, advertising, or corporate communication. It will
make high-class videos to stimulate audiences as well as convey messages and display
products or services.
                        </p>
                    </div>
                   </motion.div>

                   <motion.div className='col-md-4 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>Public Relations</h3>
                        <p>
                        It includes developing and enhancing the image of a company and its
reputation through strategic communication. PR professionals build relationships with the
media, control public opinion, and handle crises.
                        </p>
                    </div>

                    
                   </motion.div>
                  
                
                   </div>
             </>
            )}
             
           </div>
          </div>
        
       </motion.div>
   </>
  )
}

export default OtherSpecializedServices