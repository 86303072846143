import React, { useState } from "react";
import { easeInOut, motion } from "framer-motion";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
  FaLinkedin,
  FaEnvelope,
  FaMobile,
} from "react-icons/fa";
import AnimCursor from "../components/AnimCursor";
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';


function Home() {
  const [showContent, setShowContent] = useState(false); // State to manage visibility
  const [formData, setFormData] = useState({
    name:'',
        email:'',
        mobile:'',
        msg:''
      });
    
  
  
    const handleChange = (e) =>
    {
      e.preventDefault();
      setFormData({...formData, [e.target.name]:e.target.value});
      
      
    }
  
    const handleContact = (e) =>
    {
      e.preventDefault();
      axios.post('https://grphon-backend.vercel.app/sendMail2', formData).then(response=>{alert("Thankyou For Contacting Us");}).catch(err =>{console.log(err);});
    }
  const containerVariants = {
    hidden: {
      opacity: 0,
      x: "100vw",
      transition: {
        staggerChildren: 0.5,
      },
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "tween",
        staggerChildren: 0.4,
        when: "beforeChildren",
      },
    },
  };

  const homeDotPatter = {
    visible: {
      rotate: [-4, 4, -4, 4, 0],
      transition: {
        duration: 15,
        repeat: Infinity,
        repeatType: "reverse",
        ease: easeInOut,
      },
    },
    initial: {
      scale: 1,
    },
  };

  // Variants for text fade-in animation
  const textVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 1.5, // 1 second fade-in
      },
    },
  };

  const shakeVariants = {
    shaking: {
      rotate: [-10, 10, -10, 10, 0], // Back and forth rotation
      transition: {
        duration: 0.5, // Each ring cycle
        repeat: Infinity, // Repeat indefinitely
        ease: "easeInOut",
      },
    },
  };

  return (
    <>
      <AnimCursor />
      <motion.section
        
        id="home"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        onAnimationComplete={() => setShowContent(true)} // Trigger visibility
      >
      <div className="container-fluid">
        <div className="row ">
          <div className="col-12 col-md-8 col-lg-8 home-left position-relative">
            {showContent && ( // Conditionally animate text after containerVariants completes
              <>
                <motion.h5
                  variants={textVariants}
                  initial="hidden"
                  animate="visible"
                  className="text-purple"
                >
                  Welcome To,
                </motion.h5>
                <motion.h1
                  variants={textVariants}
                  initial="hidden"
                  animate="visible"
                >
                  Buzzvortex Media
                </motion.h1>
                <motion.h4
                  variants={textVariants}
                  initial="hidden"
                  animate="visible"
                >
                  Buzzvortex Media specilaizes in innovative website, tailored digital marketing strategy,along with impactful social media marketing
                </motion.h4>
                <motion.div
                  variants={textVariants}
                  initial="hidden"
                  animate="visible"
                >
                  <Link to="/about" className="btn btn-default home-read-more">
                    About Buzzvortex
                  </Link>
                  <motion.img src={`${process.env.PUBLIC_URL}/shape-7.png`} className="small-circle-pattern" variants={homeDotPatter}
              initial="initial"
              animate="visible" />
                </motion.div>
              </>
            )}
          </div>
          <div className="col-4 position-relative amoeba">
            
            <motion.img
              src={`${process.env.PUBLIC_URL}/amoeba.png`}
              className="homeDotPatter"
              variants={homeDotPatter}
              initial="initial"
              animate="visible"
            />
          </div>
        </div>
        </div>
      </motion.section>

      <motion.section
        id="about"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        onAnimationComplete={() => setShowContent(true)} // Trigger visibility
      >
        <div className="container-fluid">
            <div className="row">
              <div className="col-12 text-center">
              <motion.h2
                variants={textVariants}
                initial="hidden"
                animate="visible"
                className="about-heading"
              >
                About Buzzvortex Media
              </motion.h2>
              <motion.h2 className="about-h2"
                variants={textVariants}
                initial="hidden"
                animate="visible"
              >
                Chat Smarter, Not Harder
              </motion.h2>
              </div>
            </div>
          <div className="row about-row">
          <div className="col-12 col-md-4 col-lg-4 position-relative">
            <img src="https://corporx.themetags.com/assets/img/about-us.jpg" className="img-fluid" style={{border:"5px solid #914bf1",borderRadius:"63% 37% 30% 70% / 50% 45% 55% 50%"}} />
          </div>
            <div className="col-12 col-md-8 col-lg-8 home-left pt-5 mt-5">
           

              <motion.div
                variants={textVariants}
                initial="hidden"
                animate="visible"
              >
                <p className="text-black">
                  Buzzvortex Media is an influential and purposeful marketing
                  cohesiveness company that is all about changing marketing into
                  the ultimate business growth driver. With a good grasp of the
                  market and a push for creativity, we make use of exact
                  information and exclusive creativity to accomplish good
                  outcomes. We are a repute proofing ourselves and keeping pace
                  with digitization. Join us to experience the digital
                  transformation thence the integration of the digital world
                  with the marketing of the business.
                </p>
                <h3 className="text-purple fw-bold">Mission</h3>
                <p className="text-black">
                  Our trajectory started with a plan to redefine marketing in
                  corporate strategy since the outset, we have been faithful to
                  our mission of not only delivering but surpassing client
                  expectations by delivering exceptional outcomes. We are a
                  group of experienced professionals with the capacity to plan
                  strategically and execute the campaign at every step for our
                  clients&#39; growth.
                </p>
                <h3 className="text-purple fw-bold">Vision</h3>
                <p className="text-black">
                  In Buzzvortex Media, we have a strong feeling to be a true
                  differentiator. We are advocates of synergy and are busily
                  cooperating with our customers to navigate all the business
                  challenges that they encounter. Providing end-to-end marketing
                  solutions to enhance brand visibility and drive measurable
                  business growth is what our company is about.
                </p>
              </motion.div>
            </div>
            
          </div>
        </div>
      </motion.section>


      <motion.section
          className="custom-shape-divider-top"
         id="services"  
         variants={containerVariants}
         initial="hidden"
         animate="visible"
         exit="exit"
         onAnimationComplete={() => setShowContent(true)} // Trigger visibility
       >
        
         <div className='container-fluid ph-0 mh-5' style={{ position: "inherit", zIndex: 1 }}>
            <div className='row'>
              <div className='col-12 text-center'>
              <motion.h2
                variants={textVariants}
                initial="hidden"
                animate="visible"
                className="about-heading"
              >
                Buzzvortex Media
              </motion.h2>
              <motion.h2 className="about-h2 text-white"
                variants={textVariants}
                initial="hidden"
                animate="visible"
              >
                Services We Provide
              </motion.h2>
              </div>
            </div>
            <div className='row mt-5 d-flex align-items-stretch'>
            
                  
                   <motion.div className='col-12 col-md-4 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>Digital Marketing</h3>
                        <p>
                        Ready to make your mark? Let's talk about how we can skyrocket your business with digital marketing that actually works
                        </p>
                    </div>
                   </motion.div>

                   <motion.div className='col-12 col-md-4 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>Branding</h3>
                        <p>
                        We engage in this vital and effective platform with a detailed marketing strategy by which our
clients can utilize various social media for the promotion of products, services, or brands for
the masses. Targeting audiences is compelling because it increases brand awareness.
                        </p>
                    </div>
                   </motion.div>

                  
                    
                  
                   <motion.div className='col-12 col-md-4 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>IT Services</h3>
                        <p>
                        Engaging and functional mobile applications for the iOS and
Android platforms, offering the best user experience through user-friendly and intuitive app
interface development. Apps development with the latest technology to ensure high
performance and security.
                        
                        </p>
                    </div>

                    
                   </motion.div>
                  
                
                   </div>
            
          </div>
        
       </motion.section>

       <motion.section
          className="mt-5"
         variants={containerVariants}
         initial="hidden"
         animate="visible"
         exit="exit"
         onAnimationComplete={() => setShowContent(true)} // Trigger visibility
       >
        
         <div className='container-fluid ph-0 mh-5' style={{ position: "inherit", zIndex: 1 }}>
            <div className='row'>
              <div className='col-12 text-center'>
              <motion.h2
                variants={textVariants}
                initial="hidden"
                animate="visible"
                className="about-heading text-purple"
              >
                Buzzvortex Media
              </motion.h2>
              <motion.h2 className="about-h2 text-black"
                variants={textVariants}
                initial="hidden"
                animate="visible"
              >
                Our Clients
              </motion.h2>
              </div>
            </div>
            <div className='row mt-5 d-flex align-items-stretch'>
            <div className='col-12 col-md-12'>
            <Swiper  modules={[Autoplay]}
       
       spaceBetween={10}
       loop={true}
       autoplay={{
         delay: 0,
         disableOnInteraction: false,
       }}
       breakpoints={{
   640: {
     slidesPerView: 3, // Show 2 slides on devices with width <= 640px
     spaceBetween: 10,
   },
   1024: {
     slidesPerView: 4, // Default setting for larger screens
     spaceBetween: 10,
   },
 }}
       speed={3000}>
       
         <SwiperSlide>
         <img src={`${process.env.PUBLIC_URL}/clients/om.png`} className="gallerySlideImg"/>
         </SwiperSlide>
         <SwiperSlide>
         <img src={`${process.env.PUBLIC_URL}/clients/idfc.png`} className="gallerySlideImg"/>
         </SwiperSlide>
         <SwiperSlide>
         <img src={`${process.env.PUBLIC_URL}/clients/biba.png`} className="gallerySlideImg"/>
         </SwiperSlide>
         <SwiperSlide >
           <img src={`${process.env.PUBLIC_URL}/clients/gulshan.png`} className="gallerySlideImg"/>
          
         </SwiperSlide>
         <SwiperSlide>
         <img src={`${process.env.PUBLIC_URL}/clients/maac.png`} className="gallerySlideImg"/>
         </SwiperSlide>
         <SwiperSlide>
         <img src={`${process.env.PUBLIC_URL}/clients/collegedekho.png`} className="gallerySlideImg"/>
         </SwiperSlide>
         <SwiperSlide>
         <img src={`${process.env.PUBLIC_URL}/clients/frankfinn.png`} className="gallerySlideImg"/>
         </SwiperSlide>
     </Swiper>
            </div>

                  
                  
                   
                  
                
                   </div>
            
          </div>
        
       </motion.section>


       <motion.section
       className="mt-5"
        id="contact"  
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        onAnimationComplete={() => setShowContent(true)} // Trigger visibility
      >
        <div className='container-fluid' style={{ position: "inherit", zIndex: 1 }}>
        <div className='row'>
              <div className='col-12 text-center'>
              <motion.h2
                variants={textVariants}
                initial="hidden"
                animate="visible"
                className="about-heading"
              >
                Buzzvortex Media
              </motion.h2>
              <motion.h2 className="about-h2 text-black"
                variants={textVariants}
                initial="hidden"
                animate="visible"
              >
                Reach Us At
              </motion.h2>
              </div>
            </div>
          <div className='row about-row mt-5'>
            <div className='col-12 col-md-6 col-lg-6 home-left'>
              
                  <motion.div variants={textVariants} initial="hidden" animate="visible">
                  <form method="POST" encType='multipart/form-data'>
    <div class="row mb-3">
      <div class="col-md-6">
       
        <input type="text" class="form-control" name="name" value={formData.name} onChange={handleChange} id="firstName" placeholder="Enter your first name" />
      </div>
      <div class="col-md-6 mt-sms-3">
        
        <input type="text" class="form-control" id="lastName" placeholder="Enter your last name" />
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-6">
       
        <input type="email" class="form-control" name="email" value={formData.email} onChange={handleChange} id="email" placeholder="Enter your email" />
      </div>
      <div class="col-md-6 mt-sms-3">
        
        <input type="text" class="form-control" name="mobile" value={formData.mobile} onChange={handleChange} id="mobile" placeholder="Enter your mobile" />
      </div>
    </div>


    <div class="mb-3">
      
      <textarea class="form-control" name="msg" id="message" value={formData.msg} onChange={handleChange} rows="4" placeholder="Enter your message"></textarea>
    </div>

    <div class="mb-3">
      <button type="submit" onClick={handleContact}  class="btn btn-primary home-read-more">Submit</button>
    </div>
  </form>
                  </motion.div>
               
            </div>
            <div className='col-12 col-md-6 col-lg-6 position-relative'>
            <h4 className='text-purple' style={{textTransform:"uppercase"}}>BuzzVortex Media LLP</h4>
          <h6 className="mt-3 text-black">Address:</h6>
          <h5>601, 6th Floor, Amarpali Princely Estate, Sector 76 Noida - 201301</h5>

          <h6 className='mt-3 text-black'>Mobile:</h6>
          <h5>+91-8920046258</h5>

          <h6 className='mt-3 text-black'>Email:</h6>
          <h5>info@buzzvortexmedia.com</h5>
          <ul className='list-unstyled d-flex' style={{gap:"10px"}}>
                <li><Link to="https://www.instagram.com/buzz_vortex"><motion.div whileHover={{ scale: 1.2 }} transition={{ type: 'spring', stiffness: 300 }}>
                <FaInstagram style={{ color: "#914bf1" }} size="2em" />
                </motion.div>
                </Link></li>
                <li><Link to="https://www.facebook.com/BuzzVortexMedia/" ><motion.div whileHover={{ scale: 1.2 }} transition={{ type: 'spring', stiffness: 300 }}><FaFacebook style={{ color: "#914bf1" }} size="2em" /></motion.div></Link></li>
                <li><Link to="https://wa.me/+918920046258"><motion.div whileHover={{ scale: 1.2 }} transition={{ type: 'spring', stiffness: 300 }}><FaWhatsapp style={{ color: "#914bf1" }} size="2em" /></motion.div></Link></li>
                <li><Link><motion.div whileHover={{ scale: 1.2 }} transition={{ type: 'spring', stiffness: 300 }}><FaLinkedin style={{ color: "#914bf1" }} size="2em" /></motion.div></Link></li>
              </ul>
            </div>
          </div>

         
         
        </div>
      </motion.section>
      
    </>
  );
}

export default Home;
