import React, { useState } from 'react';
import { easeInOut, motion } from 'framer-motion';
import AnimCursor from '../components/AnimCursor';

function ItServices() {
    const [showContent, setShowContent] = useState(false); // State to manage visibility

  const containerVariants = {
    hidden: { 
      opacity: 0, 
      x: '100vw',
      transition: {
        staggerChildren: 0.5,
      } 
    },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: { 
        type: 'tween',
        staggerChildren: 0.4,
        when: "beforeChildren",
      }
    },
  };

  const homeDotPatter = {
    visible:{
      scale:[1,1.3],
      transition:{
        duration:15,
        repeat:Infinity,
        repeatType:"reverse",
        ease:easeInOut
      }
    },
    initial:{
      scale:1
    }
  };

  // Variants for text fade-in animation
  const textVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        duration: 1.5, // 1 second fade-in
      },
    },
  };
  return (
    <>
    <AnimCursor/>
    <motion.div 
         className='container-fluid' 
         id="about"  
         variants={containerVariants}
         initial="hidden"
         animate="visible"
         exit="exit"
         onAnimationComplete={() => setShowContent(true)} // Trigger visibility
       >
         <div className='container-fluid ph-0 mh-5' style={{ position: "inherit", zIndex: 1 }}>
           <div className='row d-flex align-items-center vh-100'>
           {showContent && ( // Conditionally animate text after containerVariants completes
            <>
            <div className='row pt-5 mt-5 d-flex align-items-stretch'>
            <motion.h2 variants={textVariants} initial="hidden" animate="visible" className='mb-5 fs-1 text-purple'>IT Services</motion.h2>
            
            
              
                   
                  
                   <motion.div className='col-md-3 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>Web Development</h3>
                        <p>
                        We develop a custom website tailored to meet the business
needs of our customers. We take care of user-friendly and high-performing websites. We are
keen on responsive website designs that align with brand identity.
                        </p>
                    </div>
                   </motion.div>

                   <motion.div className='col-md-3 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>App Development</h3>
                        <p>
                        Engaging and functional mobile applications for the iOS and
Android platforms, offering the best user experience through user-friendly and intuitive app
interface development. Apps development with the latest technology to ensure high
performance and security.
                        </p>
                    </div>
                   </motion.div>

                   <motion.div className='col-md-3 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>UI/UX Design</h3>
                        <p>
                        It's not just about looking good; it's about feeling good. We design user interfaces and experiences that keep your audience hooked, with seamless navigation and engaging layouts
                        </p>
                        <p>Let them know you're building more than just a product—you're creating experiences that leave a mark. </p>
                    </div>
                   </motion.div>

                   <motion.div className='col-md-3 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>Ongoing Support & Maintenance</h3>
                        <p>
                        We don’t just launch and leave you hanging. Our team’s here for the long haul, offering ongoing support, updates, and maintenance to keep your website or app running smoothly.
                        </p>
                    </div>
                   </motion.div>
                  
                
                   </div>
             </>
            )}
             
           </div>
          </div>
        
       </motion.div>
   </>
  )
}

export default ItServices