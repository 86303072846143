
import React, { useState } from 'react';
import { easeInOut, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaWhatsapp, FaLinkedin, FaEnvelope, FaMobile } from 'react-icons/fa';
import axios from 'axios';
import AnimCursor from '../components/AnimCursor';

function Contact() {
  const [showContent, setShowContent] = useState(false); // State to manage visibility
  const [formData, setFormData] = useState({
  name:'',
      email:'',
      mobile:'',
      msg:''
    });
  


  const handleChange = (e) =>
  {
    e.preventDefault();
    setFormData({...formData, [e.target.name]:e.target.value});
    
    
  }

  const handleContact = (e) =>
  {
    e.preventDefault();
    axios.post('https://grphon-backend.vercel.app/sendMail2', formData).then(response=>{alert("Thankyou For Contacting Us");}).catch(err =>{console.log(err);});
  }
 
  const containerVariants = {
    hidden: { 
      opacity: 0, 
      x: '100vw',
      transition: {
        staggerChildren: 0.5,
      } 
    },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: { 
        type: 'tween',
        staggerChildren: 0.4,
        when: "beforeChildren",
      }
    },
  };

  const homeDotPatter = {
    visible:{
      scale:[1,1.3],
      transition:{
        duration:15,
        repeat:Infinity,
        repeatType:"reverse",
        ease:easeInOut
      }
    },
    initial:{
      scale:1
    }
  };

  // Variants for text fade-in animation
  const textVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        duration: 1.5, // 1 second fade-in
      },
    },
  };
      
  return (
    <>
    <AnimCursor/>
   <motion.div 
        className='container-fluid' 
        id="contact"  
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        onAnimationComplete={() => setShowContent(true)} // Trigger visibility
      >
        <div className='container-fluid ph-0 mh-5' style={{ position: "inherit", zIndex: 1 }}>
          <div className='row d-flex align-items-center vh-100'>
            <div className='col-12 col-md-6 col-lg-6 home-left pt-5 mt-5'>
              {showContent && ( // Conditionally animate text after containerVariants completes
                <>
                 
                 
                  <motion.div variants={textVariants} initial="hidden" animate="visible">
                  <form method="POST" encType='multipart/form-data'>
    <div class="row mb-3">
      <div class="col-md-6">
       
        <input type="text" class="form-control" name="name" value={formData.name} onChange={handleChange} id="firstName" placeholder="Enter your first name" />
      </div>
      <div class="col-md-6 mt-sms-3">
        
        <input type="text" class="form-control" id="lastName" placeholder="Enter your last name" />
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-6">
       
        <input type="email" class="form-control" name="email" value={formData.email} onChange={handleChange} id="email" placeholder="Enter your email" />
      </div>
      <div class="col-md-6 mt-sms-3">
        
        <input type="text" class="form-control" name="mobile" value={formData.mobile} onChange={handleChange} id="mobile" placeholder="Enter your mobile" />
      </div>
    </div>


    <div class="mb-3">
      
      <textarea class="form-control" name="msg" id="message" value={formData.msg} onChange={handleChange} rows="4" placeholder="Enter your message"></textarea>
    </div>

    <div class="mb-3">
      <button type="submit" onClick={handleContact}  class="btn btn-primary home-read-more">Submit</button>
    </div>
  </form>
                  </motion.div>
                </>
              )}
            </div>
            <div className='col-12 col-md-6 col-lg-6 position-relative'>
            <h4 className='text-purple' style={{textTransform:"uppercase"}}>BuzzVortex Media LLP</h4>
          <h6 className="mt-3 text-black">Address:</h6>
          <h5>601, 6th Floor, Amarpali Princely Estate, Sector 76 Noida - 201301</h5>

          <h6 className='mt-3 text-black'>Mobile:</h6>
          <h5>+91-8920046258</h5>

          <h6 className='mt-3 text-black'>Email:</h6>
          <h5>info@buzzvortexmedia.com</h5>
          <ul className='list-unstyled d-flex' style={{gap:"10px"}}>
                <li><Link to="https://www.instagram.com/buzz_vortex"><motion.div whileHover={{ scale: 1.2 }} transition={{ type: 'spring', stiffness: 300 }}>
                <FaInstagram style={{ color: "#914bf1" }} size="2em" />
                </motion.div>
                </Link></li>
                <li><Link to="https://www.facebook.com/BuzzVortexMedia/" ><motion.div whileHover={{ scale: 1.2 }} transition={{ type: 'spring', stiffness: 300 }}><FaFacebook style={{ color: "#914bf1" }} size="2em" /></motion.div></Link></li>
                <li><Link to="https://wa.me/+918920046258"><motion.div whileHover={{ scale: 1.2 }} transition={{ type: 'spring', stiffness: 300 }}><FaWhatsapp style={{ color: "#914bf1" }} size="2em" /></motion.div></Link></li>
                <li><Link><motion.div whileHover={{ scale: 1.2 }} transition={{ type: 'spring', stiffness: 300 }}><FaLinkedin style={{ color: "#914bf1" }} size="2em" /></motion.div></Link></li>
              </ul>
            </div>
          </div>

          
         
        </div>
      </motion.div>
  </>
  )
}

export default Contact