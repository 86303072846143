import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
function Header() {
  return (
    
      <div className='container-fluid' id="header">
        <div className='row'>
            <div className='col-12 '>
            <nav className="navbar navbar-expand-lg p-0">
                <div className="container-fluid p-0">
                <Link className="navbar-brand" to="/">
                        <img src={`${process.env.PUBLIC_URL}/buzzvortex_logo.png`} className="logo" />
                    </Link>
                    <button className="navbar-toggler navbar-toggler-custom" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                    
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                        <Link className="nav-link active" to="/" >Home</Link>
                        </li>
                        <li className="nav-item">
                        <Link className="nav-link" to="about" >About Us</Link>
                        </li>
                       
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Services
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                <li><Link to="digital-marketing" className="dropdown-item">Digital Marketing</Link></li>
                                <li><Link to="branding" className="dropdown-item">Branding</Link></li>
                               
                                <li><Link to="traditional-marketing-services" className="dropdown-item">Traditional Marketing Services</Link></li>
                                <li><Link to="other-specialized-services" className="dropdown-item">Other Specialized Services</Link></li>
                                <li><Link to="it-services" className="dropdown-item">IT Services</Link></li>
                            </ul>
                            </li>
                        <li className="nav-item">
                        <Link className="nav-link" to="contact" >Contact Us</Link>
                        </li>
                        
                    </ul>
                    
                    </div>
                </div>
                </nav>
            </div>
        </div>
      </div>
    
  )
}

export default Header