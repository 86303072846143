import React, { useState } from 'react';
import { easeInOut, motion } from 'framer-motion';
import AnimCursor from '../components/AnimCursor';

function TraditionalMarketingServices() {
    const [showContent, setShowContent] = useState(false); // State to manage visibility

  const containerVariants = {
    hidden: { 
      opacity: 0, 
      x: '100vw',
      transition: {
        staggerChildren: 0.5,
      } 
    },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: { 
        type: 'tween',
        staggerChildren: 0.4,
        when: "beforeChildren",
      }
    },
  };

  const homeDotPatter = {
    visible:{
      scale:[1,1.3],
      transition:{
        duration:15,
        repeat:Infinity,
        repeatType:"reverse",
        ease:easeInOut
      }
    },
    initial:{
      scale:1
    }
  };

  // Variants for text fade-in animation
  const textVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        duration: 1.5, // 1 second fade-in
      },
    },
  };
  return (
    <>
    <AnimCursor/>
    <motion.div 
         className='container-fluid' 
         id="traditional"  
         variants={containerVariants}
         initial="hidden"
         animate="visible"
         exit="exit"
         onAnimationComplete={() => setShowContent(true)} // Trigger visibility
       >
         <div className='container-fluid ph-0 mh-5' style={{ position: "inherit", zIndex: 1 }}>
           <div className='row d-flex align-items-center vh-100'>
           {showContent && ( // Conditionally animate text after containerVariants completes
            <>
            <div className='row pt-5 mt-5 d-flex align-items-stretch'>
            <motion.h2 variants={textVariants} initial="hidden" animate="visible" className='mb-5 fs-1 text-purple'>Traditional Marketing Services</motion.h2>
            
            
              
                   
                  
                   <motion.div className='col-md-3 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>Print Advertising</h3>
                        <p>
                        We are fully equipped with a strong network in print media for space in a
newspaper, magazine, brochure, flyer, and other printed material to reach selected
audiences based on the readership demographics and geography of the reach of the
publication.
                        </p>
                    </div>
                   </motion.div>

                   <motion.div className='col-md-3 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>Broadcast Advertising</h3>
                        <p>
                        Promotion of brand on television stations or radio stations to make
people aware of a product or service. We are on media buying of airtime slots, the strategy &amp;
storyboard for the brand.
                        </p>
                    </div>
                   </motion.div>

                   <motion.div className='col-md-3 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>Direct Mail Marketing</h3>
                        <p>
                        We utilize physical mailers such as postcards, catalogues, and
flyers, which are placed directly into customers&#39; mailboxes. It is here that opportunities to
communicate with a target audience in a targeted fashion with demographic information
exist.
                        </p>
                    </div>

                    
                   </motion.div>
                   <motion.div className='col-md-3 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>Outdoor Advertising</h3>
                        <p>
                        We use our extensive network to advertise a product or service in
public places, such as billboards, posters, transit ads, and signage. The objective is to
capture the attention of passersby, drivers, and commuters in areas of high-volume
movement.
                        </p>
                    </div>

                    
                   </motion.div>
                
                   </div>
             </>
            )}
            
           </div>
          </div>
        
       </motion.div>
   </>
  )
}

export default TraditionalMarketingServices